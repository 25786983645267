@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*NODE_ENV indicator*/
.ribbon {
  font-size: 0.75em;
  position: fixed;
  z-index:9999;
  color: white;
  background: #ff0000;
  box-shadow: 0 0 0 999px #ff0000;
  clip-path: inset(0 -100%);
  inset: 0 auto auto 0; /* top and left equal to 0 */
  transform-origin: 100% 0; /* OR top right */
  transform: translate(-29.3%) rotate(-45deg);
}

/*
animation
*/
@keyframes animationInOut {
  0% {opacity:0}
  10% {opacity:1}
  60% {opacity:0;width:400px;height:400px;}
  100% {opacity:0;}
}
.animationFadeOut{
  animation:animationInOut 1s infinite
}

/*
file upload custom
*/
.fileUpload-custom{
  width:100%;
  background-color: rgb(247, 247, 247);
  border: 1px dashed grey!important;
  height: 230px!important;
  width:300px;
  padding: 20px!important;
}
@media screen and (max-width:767px){
  .fileUpload-custom{
    width:100%;
    background-color: rgb(247, 247, 247);
    border: 1px dashed grey!important;
    height: 230px!important;
    width:100%;
    min-width:280px!important;
    padding: 20px!important;
  } 
}
/* amplify toast */
.amplify-liveness-toast.amplify-liveness-toast--primary{
  background:#ffcb00;
}

.amplify-liveness-toast.amplify-liveness-toast--primary .amplify-flex.amplify-liveness-toast__message{
  color:#000;
}
/*webcam*/
.webcam__container{
  margin-top:15px
}
.webcam__buttons{
  display: flex; 
  align-items:center; 
  justify-content: space-between; 
  margin: 16px 8px 0; 
  padding: 0
}

.webcam__parentContainer{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  width: 774px;
  height: 694px;
  background-color: #111 ;
}

@media screen and (max-width:774px){
  .webcam__parentContainer{
    transform: translateX(-50%);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
  }
}
@media screen and (max-width:767px){
  .overlayImg{
    display:none
  }
}

